import React from 'react'

export default function Chaatadds() {
    return (
        <div className='grid grid-cols-1 lg:grid-cols-2 w-full px-[30px] sm:px-[60px] m-auto bg-black text-white z-0 p-8'>
            <div className='py-10 sm:px-8 px-2'>
                <span className='border-l-2 border-red-600 h-[40px] block font-bold'></span>
                <h2 className='text-[25px] my-2'>Deepak-Chat-Junction</h2>
                <p className='text-gray-300  my-2'>Welcome to Deepak-Chat-Junction, where we're reinventing the charm of Indian street food with a modern twist. From traditional chaat snacks to innovative flavors, we're committed to delivering an experience that's both delicious and hygienic. Our passion for authenticity drives us to perfect our craft, ensuring every bite is a delightful journey through India's culinary heritage. Join us as we embark on a mission to share the joy of chaat globally, <br /> <br /> one mouthwatering dish at a time. At Deepak-Chat-Junction, we're not just serving food; we're serving up memories that'll last a lifetime. Come taste the difference at our junction and discover why we're the talk of the town!</p>
                
            </div>
            <div className='w-full grid  grid-cols-2 py-6 px-2'>
                <img src="image/Gallery/3.jpg" className='w-full h-full' alt="" />
                <img src="image/chaat/pani.jpg" alt="" className='w-full h-full'/>
                <img src="image/Gallery/4.jpg" alt="" className='w-full h-full'/>
                <img src="image/Gallery/7.jpg" alt="" className='w-full h-full' />
            </div>


        </div>
    )
}
