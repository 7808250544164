import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Main from './Page/Main'
import Home from './Page/Home'
import Aboutpage from './Page/Aboutpage'
import Menupage from './Page/Menupage'
import Contactpage from './Page/Contactpage'

 

const routers=createBrowserRouter(
  [
    {
      path:'/',
      element:<Main/>,
      children:[
        {
          path:'/',
          element:<Home/>
        },
        {
          path:'/about',
          element:<Aboutpage/>
        },
       
        {
          path:'/contact',
          element:<Contactpage/>
        },
        
        {
          path:'/menu',
          element:<Menupage/>
        },

      ]
    }
  ]
)

export default function App() {
  return (
  <RouterProvider router={routers}/>
  )
}
