import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export default function Header() {
    const [toggle, settoggle] = useState(false)

    function demo() {
        settoggle(!toggle)
    }
    const navbar = [
        {
            name: "HOME",
            link: '/'
        }
        ,
        {
            name: "ABOUT US",
            link: '/about'
        },
        {
            name: "MENU",
            link: '/menu'
        },
        {
            name: "CONTACT US ",
            link: '/contact'
        }
    ]
    return (
        <header style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} className='w-full sticky top-0 z-50  flex items-center justify-between py-8 box-border  bg-[#d3cdcdb3] '>
         <h1 className='text-[green] font-extrabold px-4'> <span className='text-[blue]'> DEEPAK</span>  CHAT JUNCTION</h1>
            <ul className=' justify-around w-[70%] items-center text-[16px] font-bold md:flex  hidden'>
                {
                    navbar.map(
                        (data, index) => {
                            return (
                                <Link to={data.link} >   <li key={index} className='hover:text-black text-gray-600 hover:bg-[white] font-sans p-4 px-6 cursor-pointer'>{data.name}</li></Link>
                            )
                        }
                    )
                }
            </ul>
            {
                toggle == false ?
                    <FaBars onClick={demo} className=' cursor-pointer md:hidden text-[25px] mr-[30px]' />
                    :
                    <ImCross onClick={demo} style={{ zIndex: "4545" }} className=' md:hidden cursor-pointer right-[30px] text-[25px] absolute' />
            }

            <ul style={{ zIndex: "4544" }} className={`flex flex-col bg-[#d3cdcdb3]  h-screen top-[0px] absolute duration-700 w-full items-center  text-[16px] font-bold md:hidden justify-around  
                ${toggle == true ? 'left-0' : 'left-[-100%]'}

                `}>

                {
                    navbar.map(
                        (data, index) => {
                            return (
                                <Link to={data.link }>      <li key={index} className='hover:text-black text-center text-gray-600 hover:bg-slate-300 p-6 px-8 cursor-pointer'>{data.name}</li></Link>
                            )
                        }
                    )
                }
            </ul>


        </header>
    )
}
