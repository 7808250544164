import React from 'react'
import Crousel from '../componate/Crousel'
import Chaatadd from '../componate/Chaatadd'
import Panipuri from '../componate/Panipuri'
import Chaatadds from '../componate/Chaatadds'
import Contact from '../componate/Contact'
export default function Home() {
  return (
    <div>
      <Crousel/>
      <Chaatadd/>
      <Panipuri/>
      <Chaatadds/>
      <Contact/>
    </div>
  )
}
