import React from 'react'
import About from '../componate/AboutComp/About'
import Team from '../componate/AboutComp/Team'

export default function Aboutpage() {
  return (
    <div>
      <About/>
      <Team/>
    </div>
  )
}
