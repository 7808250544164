import React from 'react'

export default function Gallery() {



    const items = [
        {
            img: "image/Gallery/1.jpg"
        },
        {
            img: "image/Gallery/2.jpg"
        },
        {
            img: "image/Gallery/3.jpg"
        },
        {
            img: "image/Gallery/4.jpg"
        },
        {
            img: "image/Gallery/5.jpg"
        },
        {
            img: "image/Gallery/6.jpg"
        },
        {
            img: "image/Gallery/7.jpg"
        },
        {
            img: "image/Gallery/8.jpg"
        },
        {
            img: "image/Gallery/9.jpg"
        },
        {
            img: "image/Gallery/10.jpg"
        },
        {
            img: "image/Gallery/11.jpg"
        },
        {
            img: "image/Gallery/12.jpg"
        },
    ]


  return (
    <div className='w-full '>
        <div className='py-5 text-center'>
            <h1 className='text-[40px] font-bold '>Gallery</h1>
            <p className='text-[25px] py-5'>Thumbnail Slider trial version</p>
        </div>
            <div className='flex  flex-wrap'>
                {
                    items.map(
                        (d, i) => {
                            return (

                                <img className='md:w-1/3 w-1/2 md:h-[280px] h-[95px] ' src={d.img} alt="" />

                            )
                        }
                    )
                }

            </div>
        </div>
  )
}
