import React from 'react'

export default function Chaatadd() {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 bg-black text-white z-0 p-8'>
            <div className='flex justify-center items-center w-full h-full md:py-16 my-8 gap-6'>
                <img src="image/chaat/chaat.jpg" className='w-[20%] md:h-[250px] h-[200px]' alt="" />
                <img src="image/chaat/tablechaat.jpg" className='w-[20%] md:h-[250px] h-[200px]' alt="" />
            </div>

            <div className='py-10'>
                <span className='border-l-2 border-red-600 h-[40px] block font-bold'></span>
                <h2 className='text-[25px] my-2'>Deepak-Chat-Junction</h2>
                <p className='text-gray-300  my-2'>Welcome to Deepak-Chat-Junction, where traditional Indian street food meets modern sensibilities. With a diverse menu featuring a wide range of chaat snacks, we've perfected a unique approach that ensures both hygiene and taste. Our innovative methodology sets us apart, guaranteeing an unforgettable culinary experience. Join us on a journey to redefine street food, as we strive to share the flavors of India with the world. At Deepak-Chat-Junction, authenticity and innovation come together to create magic on your plate. Come and indulge in the delights of chaat, prepared with love and care at our junction. <br /> <br /> We invite you to join us in celebrating the vibrant culinary heritage of India. With each bite, experience the rich tapestry of flavors that define our culture. Discover why Deepak-Chat-Junction is the ultimate destination for lovers of Indian street food. So, come on over and let your taste buds embark on an unforgettable adventure!.</p>
                <p className='text-gray-300'>" Come and Join hands with most Unique food concept."

                </p>
            </div>
        </div>
    )
}
