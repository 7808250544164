import React from 'react'

export default function Contact() {
    return (
        <div className='w-full'>
            <div className='w-full'>
                <img src="image/chaat/man.jpeg" alt="" />
            </div>
            <div className='bg-black w-full'>
                <div className='w-[80%] m-auto text-white '> 
                    <div className='py-5'>
                        <h1 className='text-[22px] font-bold'>FOR ENQUIRIES CONTACT US.</h1>
                        <div className='grid grid-cols-2 md:grid-cols-2 py-5 gap-4'>
                            <input className='p-5 text-white bg-black' style={{ border: "1px solid white" }} type="text" placeholder='Your Name' />
                            <input className='p-5 text-white bg-black' style={{ border: "1px solid white" }} type="email" placeholder='Email Address' />
                            <input className='p-5 text-white bg-black' style={{ border: "1px solid white" }} type="text" placeholder='Phone Number' />
                            <input className='p-5 text-white bg-black' style={{ border: "1px solid white" }} type="text" placeholder='City' />
                            <input className='p-5 text-white bg-black col-span-2' style={{ border: "1px solid white" }} type="text" placeholder='Enquiries/Comments' />
                            <button className='bg-red-500 p-4 w-[130px]'>SUBMIT</button>

                        </div>
                      
                       

                    </div>
                </div>

            </div>

        </div>
    )
}
