import React from 'react'
import Menu from '../componate/MenuComp/Menu'
import Gallery from '../componate/MenuComp/Gallery'

export default function Menupage() {
  return (
    <div>
      <Menu/>
      <Gallery/>
    </div>
  )
}
