import React from 'react'

export default function Team() {
    return (
        <div className='w-full   Img'>
            <div className='bg-[rgba(0,0,0,.4)] h-[300px] flex justify-center items-center text-[25px]'>
                <h1 className='text-[#fff] px-10 text-center'>Enjoy fresh, local produce prepared by our inspired team</h1>
            </div>
        </div>
    )
}
