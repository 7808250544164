import React from 'react'

export default function About() {
    return (
        <div className='w-full'>
            <div>
                <img className='w-full ' src="image/chaat/chat.jpg" alt="" />
            </div>
            <div className='w-full px-10 text-[20px] py-4 ' style={{fontFamily:"Montserrat"}}>
                <h1 className='md:text-[40px] text-[30px] font-bold py-2'>Wedding Booking:</h1>
                <p className='py-2'>
                 
                Indulge your guests in an exquisite culinary experience by booking our Pani Puri station for your wedding celebration. Let them savor the burst of flavors as they enjoy the classic street food favorite.</p>
                
               
                
            </div>
            <div className='w-full px-10 text-[20px] py-4 ' style={{fontFamily:"Montserrat"}}>
                <h1 className='md:text-[40px] text-[30px] font-bold py-2'>Kitty Party Booking:</h1>
                <p className='py-2'>
                 
                Elevate your kitty party with our irresistible Pani Puri station. Enjoy a fun-filled gathering as you and your friends indulge in the crispy goodness of golgappas served with a variety of flavorful fillings.</p>
                
               
                
            </div>
            <div className='w-full px-10 text-[20px] py-4 ' style={{fontFamily:"Montserrat"}}>
                <h1 className='md:text-[40px] text-[30px] font-bold py-2'>Birthday Party Booking:</h1>
                <p className='py-2'>
                Make your birthday celebration extra special by booking our Pani Puri counter. Treat your guests to the ultimate street food experience with our crispy golgappas stuffed with tangy masala water.</p>
                
               
                
            </div>
            <div className='w-full px-10 text-[20px] py-4 ' style={{fontFamily:"Montserrat"}}>
                <h1 className='md:text-[40px] text-[30px] font-bold py-2'>Corporate Booking:</h1>
                <p className='py-2'>
                Impress your colleagues and clients with a unique catering option by booking our Pani Puri setup for your corporate event. Our freshly prepared golgappas will surely add a delightful touch to your business gatherings.</p>
                
               
                
            </div>
            


        </div>
    )
}
