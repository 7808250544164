import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // Adds a smooth scrolling animation
    });
  }
  
  return (
    <div className='w-full bg-black '>
        <div className='w-[80%] m-auto text-white py-5'>
            <ul className='flex justify-evenly items-center gap-2 lg:flex-row flex-col '>
            <li className='text-[18px]'>COPYRIGHT © 2024 Deepak-Chat-Junction</li>
                <li onClick={scrollToTop} className='hover:text-red-500'><Link to="/"> HOME</Link></li>
                <li  onClick={scrollToTop} className='hover:text-red-500'><Link to="/about"> ABOUT</Link></li>
                <li  onClick={scrollToTop} className='hover:text-red-500'><Link to="/menu"> MENU</Link></li>
                <li  onClick={scrollToTop} className='hover:text-red-500'><Link to="/contact"> CONTACT US</Link></li>
              
               
            </ul>

        </div>

    </div>
  )
}
