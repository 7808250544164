import React from 'react'
import Contact from '../componate/Contact'
import Contacts from '../componate/Contacts'

export default function Contactpage() {
  return (
    <div>
        <Contacts/>
      <Contact/>
      
    </div>
  )
}
