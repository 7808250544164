import React from 'react'
import { Carousel } from 'flowbite-react'

export default function Crousel() {
    return (

        <div className="h-56 sm:h-[350px] md:h-[400px] lg:h-[500px] z-0 relative">
            <Carousel leftControl="" rightControl="" className='absolute'>
                <img src="image/Gallery/4.jpg" className='w-full h-full' alt="..." />
                <img src="image/Gallery/2.jpg" className='w-full h-full' alt="..." />
                <img src="image/Gallery/1.jpg" className='w-full h-full' alt="..." />
                <img src="image/Gallery/3.jpg" className='w-full h-full' alt="..." />
                <img src="image/Gallery/6.jpg" className='w-full h-full' alt="..." />

            </Carousel>
           
        </div>
    )
}
