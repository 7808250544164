import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import { IoCall } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { FaEarthAmericas } from "react-icons/fa6";
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";

export default function Contact() {
  return (
    <div className='grid md:grid-cols-2 grid-cols-1'>
      <div className='w-full px-10 py-8 '>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7361.94342166574!2d75.83045300000002!3d22.692097!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fc482b846965%3A0x9fefb2d18d0c9414!2sd%2C%20199%2F4%2C%20Sector%20D%2C%20Sudama%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452009%2C%20India!5e0!3m2!1sen!2sus!4v1711079068664!5m2!1sen!2sus" className='w-full h-[350px]' referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className='w-full p-5'>
        <h2 className='text-[30px] p-3'>CONTACT US</h2>
        <ul>
          <li className='flex p-2 text-[18px] gap-3 hover:text-[#5b5bbd] cursor-pointer'> <CiLocationOn className='text-[25px]' /> 52 circular road Lalpur chowk Ranchi 834001  </li>
          <li className='flex p-2 text-[18px] gap-3 hover:text-[#5b5bbd] cursor-pointer'> <IoCall className='text-[25px]' /> 8102477596
           </li>
            <li href="https://api.whatsapp.com/send/?phone=9351870238&text=Hello%2C+I+am+interested+in your services" className='flex p-2 text-[18px] gap-3 hover:text-[#5b5bbd] cursor-pointer'> <IoCall className='text-[25px]' /> 
            9931662148  </li>
          <li className='flex p-2 text-[18px] gap-3 hover:text-[#5b5bbd] cursor-pointer '> <CiMail className='text-[25px]' /> deepakfamouschat@gmail.com  </li>
         
          <li className='flex p-2 text-[18px] gap-3 hover:text-[#5b5bbd] cursor-pointer'> <FaFacebook className='text-[25px]' /> Feacebook  </li>
          <li className='flex p-2 text-[18px] gap-3 hover:text-[#5b5bbd] cursor-pointer'> <FaInstagramSquare className='text-[25px]' /> Inatagram  </li>

        </ul>
      </div>
    </div>
  )
}
