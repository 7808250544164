import React from 'react'

export default function Menu() {
    return (
        <div className='w-full'>
            <div className='w-full '>
                <img className='w-full' src="image/chaat/chaumin.jpg" alt="" />
            </div>
            <div className='px-10' style={{ fontFamily: "Montserrat" }}>
                <h1 className='text-[40px] font-bold py-2'>Menus</h1>
                <p className='py-2'>Our menus change regularly to best showcase the produce and flavours most suited to the new seasons.</p>
                <p className='py-2'>There are some favourites we just can’t change, so you’ll always find a perfectly grilled steak, fresh or comforting hand-made items that Deepak-Chat-Junction is increasingly renowned for. Deepak-Chat-Junction is an Impressive with its wow Menu Verities Dishes.</p>
                <p className='py-2'>Deepak-Chat-Junction is best described as indescribable . Sometimes its a family restaurant, other times its a place to hang out. Its a place for lunch and a place for dinner. Sometimes its a place in between meals. Its a place to go when you want a snack. Its a place to go when you want to get stuffed. Its an excuse to get out of the house.its an excuse not to go back home. Its different things,to different people, at different times.</p>
                <p className=''>Pani puri. <br />
<br />Stuff golgappa. 
<br />Aloo Tikki Chat with chhole. 
<br />Aloo Tikki chaat. 
<br />Dahi bhalla 
<br />papdi chaat. 
<br />Lachha tokri chaat. 
<br />Special Dahi bhalla. 
<br />Basket chaat. 
<br />Bharma golgappa chaat. 
<br />Bhelpuri. 
<br />Batata Puri. 
<br />Litti chokha. 
<br />Chilla. 
<br />Manchurian. 
<br />Chaumin.etc 
                </p>
                
            </div>
            

        </div>
    )
}
